<template>
  <div class="view">
    <m-form-skeleton :loading="fromLoading">
      <div class="serach_icon">搜索图标</div>
      <div class="icon_list">
        <span
          v-for="(item, index) in iconList"
          :key="index"
          :class="{ icon_active: item.isavtive }"
          @click="tabIcon(index)"
          ><i :class="item.iconClass"></i
        ></span>
      </div>
      <div class="serach_icon">图标描述</div>
      <div class="form">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formInput.icon_desc"
        />
      </div>
      <el-form :model="formInput" :rules="rules" ref="ruleForm">
        <div class="serach_icon">
          <span>图标位置（PC端）</span>
          <el-button type="primary" @click="add(1)">添加</el-button>
        </div>

        <div v-for="(pc_item, pc_i) in formInput.icon_pc_position" :key="pc_i">
          <div class="icon_list">
            <div class="input_from">
              <span>页面元素位置</span>
              <span>
                <el-form-item prop="icon_pc_position">
                  <el-input v-model="pc_item.dom_id" size="medium" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
          <div class="icon_list">
            <div class="input_from">
              <span>距右侧边距</span>
              <span>
                <el-form-item>
                  <el-input v-model="pc_item.position" size="medium" placeholder="建议50-180之间">
                    <template #suffix> px </template>
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
          <i class="el-icon-delete del_size" @click="del(1, pc_i)"></i>
        </div>
        <div class="serach_icon">
          <span>图标位置（移动端）</span>
          <el-button type="primary" @click="add(2)">添加</el-button>
        </div>
        <div v-for="(wap_item, wap_i) in formInput.icon_wap_position" :key="wap_i">
          <div class="icon_list">
            <div class="input_from">
              <span>页面元素位置</span>
              <span>
                <el-form-item>
                  <el-input v-model="wap_item.dom_id" size="medium" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
          <div class="icon_list">
            <div class="input_from">
              <span>距右侧边距</span>
              <span>
                <el-form-item>
                  <el-input v-model="wap_item.position" size="medium" placeholder="建议50-180之间">
                    <template #suffix> px </template>
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
          <i class="el-icon-delete del_size" @click="del(2, wap_i)"></i>
        </div>
      </el-form>
    </m-form-skeleton>
  </div>
  <div class="saveBtn" v-show="!fromLoading">
    <el-button type="primary" @click="save" :loading="loading">保存</el-button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { saveBase, Config } from '../../api/BasicSetting';
import { ElMessage } from 'element-plus';

export default {
  name: 'Index',
  setup() {
    const iconList = ref([
      {
        iconClass: 'iconfont-imageserach icon-xiangji3',
        isavtive: true,
      },
      {
        iconClass: 'iconfont-imageserach icon-tubiaozhizuomoban',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji1',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji2',
        isavtive: false,
      },
    ]);
    const rules = ref({
      icon_pc_position: [{ required: true, message: '请输入pc端右侧边距', trigger: 'blur' }],
      icon_wap_position: [{ required: true, message: '请输入移动端右侧边距', trigger: 'blur' }],
    });
    const loading = ref(false);
    const fromLoading = ref(true);
    const formInput = ref({
      icon: iconList.value[0].iconClass, //图标
      icon_desc: '', //图标描述
      icon_pc_position: [], //pc距右侧边距
      icon_wap_position: [], //移动距右侧边距
    });
    const tabIcon = index => {
      iconList.value.map(res => {
        res.isavtive = false;
      });
      iconList.value[index].isavtive = true;
      formInput.value.icon = iconList.value[index].iconClass;
    };
    const getConfig = async () => {
      fromLoading.value = true;
      const { data } = await Config({ type: 1 });
      if (data.param.icon) {
        iconList.value.map(res => {
          res.isavtive = false;
          if (data.param.icon === res.iconClass) {
            res.isavtive = true;
          }
        });
      }
      data.param.enable_status = data.param.enable_status == 1 ? true : false;
      formInput.value = data.param;
      fromLoading.value = false;
    };
    const add = type => {
      let position_obj = {
        dom_id: '',
        position: '',
      };
      if (type == 1) {
        formInput.value.icon_pc_position.push(position_obj);
      } else {
        formInput.value.icon_wap_position.push(position_obj);
      }
    };
    const del = (type, index) => {
      if (type == 1) {
        formInput.value.icon_pc_position.splice(index, 1);
      } else {
        formInput.value.icon_wap_position.splice(index, 1);
      }
    };

    const ruleForm = ref();
    const save = () => {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          formInput.value.type = 1;
          saveBase(formInput.value)
            .then(res => {
              loading.value = false;
              if (res.status == 1) {
                ElMessage.success({
                  message: '操作成功',
                  type: 'success',
                });
                getConfig();
              }
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return {
      iconList,
      rules,
      loading,
      fromLoading,
      getConfig,
      ruleForm,
      save,
      formInput,
      tabIcon,
      add,
      del,
    };
  },
  mounted() {
    this.getConfig();
  },
};
</script>

<style scoped lang="scss">
@import '../../statics/scss/iconfont';
.view {
  padding: 15px;
  .serach_icon {
    padding: 20px 0;
  }
  .del_size {
    font-size: 20px;
    margin-left: 15px;
  }
  .icon_list {
    display: inline-flex;
    background-color: #f6f6f6;
    padding: 20px;
    box-sizing: border-box;
    .el-form-item {
      margin: 0;
    }
    .input_from {
      display: flex;
      align-items: center;
      > span:first-child {
        margin-right: 15px;
      }
    }
    > span {
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 2px;
      border: solid 1px #d7dde3;
      background-color: #fff;
      margin-right: 13px;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
      i {
        color: #666;
        font-size: 30px;
      }
    }
    .icon_active {
      border: 1px solid #666698;
      i {
        color: #666698;
      }
    }
  }
  ::v-deep(textarea) {
    width: 500px;
  }
}
.saveBtn {
  background-color: #f6f6f6;
  padding: 15px;
  box-sizing: border-box;
  button {
    width: 187px;
  }
}
</style>
